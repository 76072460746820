import {
  HoverCard,
  HoverCardType,
  IPlainCardProps,
  IRawStyle,
  Modal,
  Separator,
  Text,
  mergeStyles,
} from "@fluentui/react";
import { FC } from "react";
import { HorizontalStack, VerticalStack } from "../../Stack";
import { colorPallete } from "../../../assets/color";
import { UserModel } from "../../../models";
import { t } from "i18next";
import { getFullName } from "../../../utils";

export const EmailTemplateModal: FC<any> = (props) => {
  const ModalStyles = {
    main: {
      width: "640px",
      height: "max-content",
      borderRadius: "12px",
      backgroundColor: "#EFECF9",
      padding: "8px",
    },
  };
  const container = {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
  };
  const scrollableContent: IRawStyle = {
    overflow: "hidden",
  };

  interface Props1 {
    recipients: UserModel[] | undefined;
    fileType: string | undefined;
  }
  const RenderPerson: FC<Props1> = (props: any) => {
    const { recipients } = props;
    const remainingCount = recipients && recipients.length - 1;
    if (props.fileType === "received" && recipients?.length === 0) {
      return <Text style={styles.Info}>Me</Text>;
    } else {
      return recipients && recipients.length ? (
        <div style={{ ...styles.recepientList, wordWrap: "break-word" }}>
          <HorizontalStack style={{ width: "100%", flexWrap: "wrap" }}>
            {props.fileType === "received" ? (
              <Text style={styles.Info}>Me</Text>
            ) : (
              <Text style={styles.Info}>{getFullName(recipients[0])}</Text>
            )}

            {props.fileType === "received" ? (
              <RecipientHoverCard recipients={recipients} />
            ) : (
              remainingCount > 0 && (
                <RecipientHoverCard recipients={recipients.slice(1)} />
              )
            )}
          </HorizontalStack>
        </div>
      ) : (
        <></>
      );
    }
  };

  const RecipientHoverCard: React.FC<{
    recipients: UserModel[] | undefined;
  }> = ({ recipients }) => {
    const plainCardProps: IPlainCardProps = {
      onRenderPlainCard: () => (
        <div
          style={{
            padding: "10px",
            backgroundColor: "#FFFFFF",
            fontSize: "16px",
            fontWeight: 400,
            fontFamily: "Poppins",
            color: "#323232",
          }}
        >
          <ul style={{ listStyleType: "none" }}>
            {recipients?.map((recipient, index) => (
              <li key={getFullName(recipient)}>{getFullName(recipient)}</li>
            ))}
          </ul>
        </div>
      ),
      directionalHint: 1,
    };
    return (
      <HoverCard type={HoverCardType.plain} plainCardProps={plainCardProps}>
        <div style={styles.RecepientHoverCard}>
          ,&nbsp;{`+${recipients?.length} `}
          {recipients?.length === 1 ? t("other") : t("others")}
        </div>
      </HoverCard>
    );
  };
  return (
    <Modal
      styles={ModalStyles}
      isOpen={props.isModalOpen}
      onDismiss={props.hideModal}
      containerClassName={mergeStyles(container)}
      scrollableContentClassName={mergeStyles(scrollableContent)}
    >
      <VerticalStack
        style={{
          width: "624px",
          maxHeight: "100vh",
          border: "1px solid #EFECF9",
          borderRadius: "8px",
          backgroundColor: "white",
          overflow: "hidden",
        }}
      >
        <HorizontalStack
          horizontalAlign="space-between"
          style={{
            width: "69%",
            height: "78px",
            borderRadius: "8px 8px 0px 0px",
            padding: "24px",
            gap: "24px",
            alignSelf: "flex-end",
            alignItems: "center",
          }}
        >
          <img
            src={require("../../../assets/Icons/newLogo.png")}
            style={{ width: "162px", height: "36px" }}
            alt=""
          />
          <img
            onClick={props.hideModal}
            style={{ width: "13.11px", height: "13.11px", cursor: "pointer" }}
            src={require("../../../assets/Icons/cross.png")}
            alt=""
          />
        </HorizontalStack>
        <Separator />
        {props && (
          <VerticalStack
            style={{
              width: "100%",
              height: "max-content",
              borderRadius: "0px 0px 8px 8px ",
              padding: "24px",
              gap: "10px",
              backgroundColor: "",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <VerticalStack
              style={{
                height: "max-content",
                backgroundColor: "",
              }}
            >
              <HorizontalStack
                style={{ width: "100%", height: "28px", gap: "5px" }}
              >
                <Text
                  style={{
                    fontSize: "16px",
                    lineHeight: "28px",
                    fontWeight: 400,
                    fontFamily: "Poppins",
                    color: "#717070",
                    width: "70px",
                  }}
                >
                  {t("from")}:
                </Text>
                <Text style={styles.blackText}>
                  {props.sharedBy && (getFullName(props?.sharedBy) ?? "")}
                </Text>
              </HorizontalStack>
              <HorizontalStack
                style={{
                  width: "100%",
                  height: "fit-content",
                  gap: "5px",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: "16px",
                    lineHeight: "28px",
                    fontWeight: 400,
                    fontFamily: "Poppins",
                    color: "#717070",
                    width: "70px",
                  }}
                >
                  {t("to")}:
                </Text>
                {props.fileType === "received" ? (
                  <RenderPerson
                    recipients={props?.receivedList}
                    fileType={props.fileType}
                  />
                ) : (
                  <RenderPerson
                    recipients={props?.sharedTo}
                    fileType={props.fileType}
                  />
                )}
              </HorizontalStack>
              <HorizontalStack style={{ width: "100%", gap: "5px" }}>
                <Text
                  style={{
                    fontSize: "16px",
                    lineHeight: "28px",
                    fontWeight: 400,
                    fontFamily: "Poppins",
                    color: "#717070",
                    width: "70px",
                  }}
                >
                  {t("Subject")}:
                </Text>
                <Text style={styles.blackText}>{props.subject}</Text>
              </HorizontalStack>
            </VerticalStack>
            <VerticalStack
              style={{
                width: "576px",
                height: "fit-content",
                backgroundColor: "#F9F9FF",
                padding: "24px",
                gap: "10px",
              }}
            >
              <VerticalStack style={{ width: "528px", gap: "8px" }}>
                <Text
                  style={{
                    fontSize: "16px",
                    lineHeight: "28.8px",
                    fontWeight: 400,
                    fontFamily: "Poppins",
                    color: "#323232",
                  }}
                >
                  {props?.emailNotes ?? ""}
                </Text>
                {props?.sharedBy ? (
                  <>
                    <Text
                      style={{
                        fontSize: "16px",
                        lineHeight: "28px",
                        fontWeight: 400,
                        fontFamily: "Poppins",
                        color: "#323232",
                      }}
                    >
                      {getFullName(props?.sharedBy) ?? ""}
                    </Text>
                    <Text
                      style={{
                        fontSize: "16px",
                        lineHeight: "28px",
                        fontWeight: 400,
                        fontFamily: "Poppins",
                        color: "#007CFF",
                        textDecorationLine: "underline",
                      }}
                    >
                      {props?.sharedBy?.email ?? ""}
                    </Text>
                  </>
                ) : null}
                <Text
                  style={{
                    fontSize: "16px",
                    lineHeight: "28px",
                    fontWeight: 400,
                    fontFamily: "Poppins",
                    color: "#323232",
                  }}
                >
                  Project: {props?.projectName}
                </Text>
              </VerticalStack>
            </VerticalStack>
          </VerticalStack>
        )}
      </VerticalStack>
    </Modal>
  );
};

const styles = {
  recepientList: {
    display: "inline-block",
    height: "100%",
    // width: "90%",
  },
  Info: {
    fontSize: "16px",
    lineHeight: "28px",
    color: colorPallete.black,
    fontWeight: 400,
    fontFamily: "Poppins",
  },
  RecepientHoverCard: {
    fontSize: "16px",
    borderRadius: "1rem",
    minWidth: "1px",
    maxWidth: "fit-content",
    color: "#18468F",
    cursor: "pointer",
    lineHeight: "28px",
    fontFamily: "Poppins",
  },
  blackText: {
    fontSize: "16px",
    lineHeight: "28px",
    fontWeight: 400,
    fontFamily: "Poppins",
    color: "#1F2430",
  },
};
