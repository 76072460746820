import React from "react";

function ImportIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <rect x="1.5" y="13.3984" width="17" height="5" fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.57613 0.574173C9.81044 0.339859 10.1903 0.339859 10.4247 0.574173L15.4247 5.57417C15.659 5.80849 15.659 6.18839 15.4247 6.4227C15.1903 6.65702 14.8104 6.65702 14.5761 6.4227L10.6004 2.44697V12.9984C10.6004 13.3298 10.3318 13.5984 10.0004 13.5984C9.66902 13.5984 9.40039 13.3298 9.40039 12.9984V2.44697L5.42465 6.4227C5.19034 6.65702 4.81044 6.65702 4.57613 6.4227C4.34181 6.18839 4.34181 5.80849 4.57613 5.57417L9.57613 0.574173ZM1.00039 12.3984C1.33176 12.3984 1.60039 12.6671 1.60039 12.9984V16.9984C1.60039 17.3697 1.74789 17.7258 2.01044 17.9884C2.27299 18.2509 2.62909 18.3984 3.00039 18.3984H17.0004C17.3717 18.3984 17.7278 18.2509 17.9903 17.9884C18.2529 17.7258 18.4004 17.3697 18.4004 16.9984V12.9984C18.4004 12.6671 18.669 12.3984 19.0004 12.3984C19.3318 12.3984 19.6004 12.6671 19.6004 12.9984V16.9984C19.6004 17.688 19.3265 18.3493 18.8389 18.8369C18.3513 19.3245 17.69 19.5984 17.0004 19.5984H3.00039C2.31083 19.5984 1.64951 19.3245 1.16191 18.8369C0.674318 18.3493 0.400391 17.688 0.400391 16.9984V12.9984C0.400391 12.6671 0.66902 12.3984 1.00039 12.3984Z"
        fill="#323232"
      />
    </svg>
  );
}
export default ImportIcon;
