import React, { FC, useEffect, useState } from "react";
import { Stack, Text } from "@fluentui/react";
import "./progress.css";
import { t } from "i18next";

type ProgressBarProps = {
  progress: number;
  total: number;
  isSuccess: boolean;
};
const ProgressBar: FC<ProgressBarProps> = (props) => {
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    let percentage =
      props.total !== 0 ? (props.progress / props.total) * 100 : 0;
    if (props.isSuccess) {
      setProgress(percentage >= 80 ? 100 : percentage);
    } else {
      setProgress(Math.min(Math.round(percentage), 80));
    }
  }, [props.progress, props.total, props.isSuccess]);

  return (
    <Stack className="progressBarContainer">
      <Text className="progressBarText">
        {progress < 80 ? t("importing_docs") : t("processing_docs")}
      </Text>
      <Stack className="progressBarWrapper">
        <Stack
          className="progressBar"
          style={{
            width: `${progress}%`,
            transition: "width 0.3s ease-in-out",
          }}
        >
          <Stack className="progressText">{`${progress}%`}</Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ProgressBar;
