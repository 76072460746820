import { Image } from "@fluentui/react";
import { Providers } from "@microsoft/mgt-element";
import axios from "axios";
import { useEffect, useState } from "react";

const cacheName = "thumbnailCache";

interface ThumbnailProps {
  driveItemId: string | undefined;
  hoverId?: boolean;
  height: number;
  width?: number;
  openFile?: any;
}

export const Thumbnails: React.FC<ThumbnailProps> = (props: ThumbnailProps) => {
  const [thumbnailUrl, setThumbnailUrl] = useState("");

  const getStoredThumbnail = async () => {
    const cache = await caches.open(cacheName);
    const cachedResponse = await cache.match(props.driveItemId!);
    if (cachedResponse) {
      const { url, timestamp } = await cachedResponse.json();
      if (Date.now() - timestamp < 3600000) {
        return url;
      } else {
        cache.delete(props.driveItemId!);
      }
    }
    return null;
  };

  const getImageUrl = async () => {
    const cachedThumbnail = await getStoredThumbnail();
    if (cachedThumbnail) {
      setThumbnailUrl(cachedThumbnail);
    } else {
      try {
        const response = await axios.get(
          `https://graph.microsoft.com/v1.0/sites/${process.env.REACT_APP_SITEID}/drive/items/${props.driveItemId}/thumbnails/0/large/url`,
          {
            headers: {
              Authorization: `Bearer ${await Providers.globalProvider.getAccessToken()}`,
            },
          }
        );

        const url = response.data.value.split("&cb=")[0];
        setThumbnailUrl(url);

        const cache = await caches.open(cacheName);
        const cachedData = {
          // url,
          timestamp: Date.now(),
        };
        const cachedResponse = new Response(JSON.stringify(cachedData), {
          headers: { "content-type": "application/json" },
        });

        await cache.put(props.driveItemId!, cachedResponse);
      } catch (error) {
        console.error("Error fetching or caching image:", error);
      }
    }
  };

  useEffect(() => {
    getImageUrl();
  }, [props.driveItemId]);

  return (
    <Image
      src={thumbnailUrl}
      width={props.width ? props.width : "full"}
      height={props.height}
      style={{
        ...(props.hoverId
          ? {
              filter: "brightness(32%)",
              borderTopRadius: "0.4rem",
            }
          : null),
      }}
      onClick={() => (props.openFile ? props.openFile() : null)}
    />
  );
};
