import {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  useState,
} from "react";
import Forward from "./icons/Forward";
import Reply from "./icons/Reply";
import Replyall from "./icons/Replyall";
import { VerticalDivider } from "@fluentui/react";
import Unrestricted from "./icons/Unrestricted";
import Classified from "./icons/Classified";
import EyesOnly from "./icons/EyesOnly";
import Multiple from "./icons/Multiple";
import { HorizontalStack } from "../../Stack";
import { useTranslation } from "react-i18next";

function createToggleButtonStyles(backgroundColor: string, color: string) {
  return {
    display: "flex",
    minWidth: "fitContent",
    padding: "6px 8px",
    justifyContent: "flex-start",
    gap: "8px",
    borderRadius: "4px",
    border: "1px solid #ECF0F9",
    background: backgroundColor,
    color: color,
    fontSize: "12px",
    fontFamiy: "Poppins",
    fontWeight: "400",
    cursor: "pointer",
    alignItens: "center",
    fontFamily: "Poppins",
  };
}

const buttonGroupStyle = {
  gap: "7px",
  borderRadius: "8px",
  alignItems: "center",
  padding: "6px",
  justifyContent: "space-around",
  border: "1px solid #D8E1EB",
  boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.08)",
  background: "#FFF",
  width: "100%",
};
type toggleButtonType = {
  bgcolor?: string;
  color?: string;
  disabled?: boolean;
  icon: ReactElement<any, any>;
  text: string;
  id?: number;
  setHoverId?: Dispatch<SetStateAction<number>>;
  setSelectedId?: Dispatch<SetStateAction<number>>;
  getPotentialRecipients?: any;
};
const ToggleButton: FC<toggleButtonType> = (props) => {
  const buttonStyle = createToggleButtonStyles(props.bgcolor!, props.color!);
  return (
    <div
      style={buttonStyle}
      onMouseEnter={() => props?.setHoverId!(props.id!)}
      onMouseLeave={() => props?.setHoverId!(-1)}
      onClick={() => {
        props.setSelectedId!(props.id!);
        props.getPotentialRecipients();
      }}
    >
      {props.icon}
      {props.text}
    </div>
  );
};
type ToggleButtonGroupProps = {
  selectedId?: number;
  setSelectedId?: Dispatch<SetStateAction<number>>;
  getPotentialRecipients?: any;
};

const ToggleButtonGroup: FC<ToggleButtonGroupProps> = ({
  selectedId,
  setSelectedId,
  getPotentialRecipients,
}) => {
  const [hoverId, setHoverId] = useState(-1);
  const { t } = useTranslation();
  return (
    <HorizontalStack style={buttonGroupStyle}>
      <ToggleButton
        bgcolor={hoverId === 0 || selectedId === 0 ? "#D8E1EB" : "#FFF"}
        color="#18468F"
        icon={<Forward />}
        text={t("forward")}
        id={0}
        setHoverId={setHoverId}
        setSelectedId={setSelectedId}
        getPotentialRecipients={getPotentialRecipients}
      />
      <ToggleButton
        bgcolor={hoverId === 1 || selectedId === 1 ? "#D8E1EB" : "#FFF"}
        color="#18468F"
        icon={<Reply />}
        text={t("reply")}
        id={1}
        setHoverId={setHoverId}
        setSelectedId={setSelectedId}
        getPotentialRecipients={getPotentialRecipients}
      />
      <ToggleButton
        bgcolor={hoverId === 2 || selectedId === 2 ? "#F6EAE7" : "#FFF"}
        color="#A82C0C"
        icon={<Replyall />}
        text={t("reply_all")}
        id={2}
        setHoverId={setHoverId}
        setSelectedId={setSelectedId}
        getPotentialRecipients={getPotentialRecipients}
      />
      <VerticalDivider
        styles={{
          divider: {
            height: "30px",
            backgroundColor: "#ECF0F9",
          },
        }}
      />
      <ToggleButton
        bgcolor={"#EAEAEA"}
        color="#717070"
        icon={<Unrestricted />}
        text={t("unrestricted")}
        disabled={true}
        id={3}
        setHoverId={setHoverId}
        setSelectedId={setSelectedId}
      />
      <ToggleButton
        bgcolor={"#EAEAEA"}
        color="#717070"
        icon={<Classified />}
        text={t("classified")}
        disabled={true}
        id={4}
        setHoverId={setHoverId}
        setSelectedId={setSelectedId}
      />
      <ToggleButton
        bgcolor={"#EAEAEA"}
        color="#717070"
        icon={<EyesOnly />}
        text={t("eyes_only")}
        disabled={true}
        id={5}
        setHoverId={setHoverId}
        setSelectedId={setSelectedId}
      />
      <VerticalDivider
        styles={{
          divider: {
            height: "30px",
            backgroundColor: "#ECF0F9",
          },
        }}
      />
      <ToggleButton
        bgcolor={"#EAEAEA"}
        color="#717070"
        icon={<Multiple />}
        text={t("multiple")}
        disabled={true}
        id={6}
        setHoverId={setHoverId}
        setSelectedId={setSelectedId}
      />
    </HorizontalStack>
  );
};

export default ToggleButtonGroup;
