import React from "react";

function ReceivedIcon() {
  return (
 
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.09961 12.0016C4.09961 11.7806 4.2787 11.6016 4.49961 11.6016H11.4996C11.7205 11.6016 11.8996 11.7806 11.8996 12.0016C11.8996 12.2225 11.7205 12.4016 11.4996 12.4016H4.49961C4.2787 12.4016 4.09961 12.2225 4.09961 12.0016Z" fill="#1F2430"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.09961 15.0016C4.09961 14.7806 4.2787 14.6016 4.49961 14.6016H11.4996C11.7205 14.6016 11.8996 14.7806 11.8996 15.0016C11.8996 15.2225 11.7205 15.4016 11.4996 15.4016H4.49961C4.2787 15.4016 4.09961 15.2225 4.09961 15.0016Z" fill="#1F2430"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.09961 9.00156C4.09961 8.78065 4.2787 8.60156 4.49961 8.60156H6.49961C6.72052 8.60156 6.89961 8.78065 6.89961 9.00156C6.89961 9.22248 6.72052 9.40156 6.49961 9.40156H4.49961C4.2787 9.40156 4.09961 9.22248 4.09961 9.00156Z" fill="#1F2430"/>
<path d="M20.5 10.8618V19.1567C20.5 20.1705 19.645 21 18.6 21H3.4C2.355 21 1.5 20.1705 1.5 19.1567V9.01843L4.54 7.17511V2.56682C4.54 1.64516 5.3 1 6.155 1H16.225C17.175 1 17.84 1.73733 17.84 2.56682V7.54378H17.935L20.5 9.01843C20.5 9.01843 20.5 9.84793 20.5 10.8618Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.86488 6.79155C4.9809 6.97955 4.92254 7.226 4.73455 7.34202L1.89961 9.09148V19.135C1.89961 19.9359 2.56646 20.6016 3.39016 20.6016H18.5145C18.9094 20.6016 19.3074 20.415 19.6121 20.1058C19.9171 19.7964 20.0996 19.3934 20.0996 19.0016V9.00435L17.7454 7.62686C17.5547 7.5153 17.4906 7.27029 17.6021 7.07961C17.7137 6.88894 17.9587 6.82481 18.1494 6.93637L20.7016 8.42971C20.8242 8.50146 20.8996 8.63287 20.8996 8.77495V19.0016C20.8996 19.6365 20.6095 20.2335 20.1819 20.6674C19.754 21.1016 19.1594 21.4016 18.5145 21.4016H3.39016C2.13425 21.4016 1.09961 20.3873 1.09961 19.135V8.86828C1.09961 8.72956 1.17149 8.60074 1.28955 8.52788L4.31442 6.66122C4.50242 6.5452 4.74887 6.60356 4.86488 6.79155Z" fill="#A82C0C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.68365 1.18955C5.03082 0.840556 5.50401 0.601562 6.08954 0.601562H15.6291C16.8808 0.601562 17.8996 1.62878 17.8996 2.8819V11.6255C17.8996 11.8464 17.7205 12.0255 17.4996 12.0255C17.2787 12.0255 17.0996 11.8464 17.0996 11.6255V2.8819C17.0996 2.06665 16.435 1.40156 15.6291 1.40156H6.08954C5.73982 1.40156 5.4648 1.53864 5.25082 1.75375C5.04587 1.95978 4.89961 2.32799 4.89961 2.59985V11.6255C4.89961 11.8464 4.72052 12.0255 4.49961 12.0255C4.2787 12.0255 4.09961 11.8464 4.09961 11.6255V2.59985C4.09961 2.11958 4.32745 1.54762 4.68365 1.18955Z" fill="#A82C0C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.15923 9.7916C1.27522 9.60359 1.52167 9.5452 1.70968 9.66119L8.70302 13.9755C10.0876 14.8103 11.9117 14.8103 13.2963 13.9755C13.2969 13.9752 13.2974 13.9748 13.2979 13.9745L20.2896 9.66119C20.4777 9.5452 20.7241 9.60359 20.8401 9.7916C20.9561 9.97962 20.8977 10.2261 20.7097 10.342L13.7113 14.6594C12.0718 15.649 9.92755 15.649 8.28799 14.6594L8.28466 14.6574L1.28965 10.342C1.10163 10.2261 1.04325 9.97962 1.15923 9.7916Z" fill="#A82C0C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.09961 10.0016C7.09961 9.78065 7.2787 9.60156 7.49961 9.60156H14.4996C14.7205 9.60156 14.8996 9.78065 14.8996 10.0016C14.8996 10.2225 14.7205 10.4016 14.4996 10.4016H7.49961C7.2787 10.4016 7.09961 10.2225 7.09961 10.0016Z" fill="#A82C0C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.09961 7.00156C7.09961 6.78065 7.2787 6.60156 7.49961 6.60156H14.4996C14.7205 6.60156 14.8996 6.78065 14.8996 7.00156C14.8996 7.22248 14.7205 7.40156 14.4996 7.40156H7.49961C7.2787 7.40156 7.09961 7.22248 7.09961 7.00156Z" fill="#A82C0C"/>
</svg>

  );
}

export default ReceivedIcon;
